import React, { Fragment } from 'react';
import Home from '../../components/Home/Home';

const HomePage = () => {
  return (
    <Fragment>
      <Home/>
    </Fragment>
  )
}

export default HomePage
