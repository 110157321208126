import React, { Fragment } from 'react';
import { HairLengthData } from "../../../src/utils/quizStaticData";
import svg from "../../../src/assests/images/Group 37811.svg"
import { useDispatch, useSelector } from 'react-redux';
import { setHairLength } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HairLength = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { hairLength } = useSelector(state => state?.quiz);
    return (
        <Fragment >
            <div className='hairLength centerCommon'>
                <div className='widthCommon centerCommon'>
                    <h2>
                        HAIR LENGTH
                    </h2>
                    <p>
                        How long is the bulk of your hair when straightened or pulled taut?
                        <br /> (tick one box)
                    </p>
                </div>
                <div className='hairLength-type row'>
                    {
                        HairLengthData?.map((data, index) => {
                            return (
                                <div
                                    key={index}
                                    className='col-6'
                                    onClick={() => {
                                        dispatch(setHairLength(data?.id));
                                    }}
                                >
                                    <LazyLoadImage
                                        src={data?.image}
                                        alt={data?.alt}
                                    />
                                    <h5>{data?.title}</h5>
                                    <label key={data?.id} className="custom-radio">
                                        <input
                                            type="radio"
                                            name="option"
                                            value={hairLength === data?.id}
                                            checked={hairLength === data?.id}
                                            className="radio-input"
                                            onChange={() => { }}
                                        />
                                        <span className="radio-checkmark">
                                            <img
                                                className="checkmark"
                                                src={svg}
                                                alt=""
                                            />
                                        </span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='btndiv'>
                    <button
                        className='btn btncommon btn-back'
                        onClick={() => {
                            navigate('/quiz/hairConcern');
                        }}
                    >
                        BACK
                    </button>
                    <button
                        className='btn-bg btncommon'
                        onClick={() => {
                            navigate('/quiz/lifeStyle1');
                        }}
                    >
                        NEXT
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default HairLength
