import React, { Fragment, useEffect, useState } from 'react';
import "./footer.scss";
import footer1 from "../../assests/images/footer-4.png";
import footer2 from "../../assests/images/footer-3.png";
import footer3 from "../../assests/images/footer-5.png";
import footer4 from "../../assests/images/footer-2.png";
import footer5 from "../../assests/images/footer-1.png";
import { useNavigate } from 'react-router';

const Footer = () => {
  let scroll;
  const [flag, setFlag] = useState(0)
  const navigate = useNavigate();
  useEffect(() => {
    scroll = document.querySelector('.scrollbar');
  }, [flag]);

  const handleClick = () => {
    scroll.style.transition = "0.3s";
    if (scroll) {
      if (flag === 1) {
        scroll.style.left = '0%';
        navigate("/")
      }
      if (flag === 2) {
        scroll.style.left = '10%';
      }
      if (flag === 3) {
        scroll.style.left = '30%';
      }
      if (flag === 4) {
        scroll.style.left = '52%';
      }
      if (flag === 5) {
        scroll.style.right = 0;
      }
    }
  };
  useEffect(() => {
    handleClick();
  }, [flag])
  return (
    <Fragment>
      {
        <div className="appFooter">
          <div className='appFooter-info'>
            <div
              className='dataDiv'
              data-index="1"
              onClick={() => {
                setFlag(1)
              }}
            >
              <img className='firstImg' src={footer1} alt="icon" />
            </div>
            <div
              className='dataDiv'
              data-index="2"
              onClick={() => {
                setFlag(2)
              }}
            >
              <img className='secongImg' src={footer2} alt="icon" />
            </div>
            <div
              className='dataDiv'
              data-index="3"
              onClick={() => {
                setFlag(3)
              }}
            >
              <img className='thirdImg' src={footer3} alt="icon" />

            </div>
            <div
              className='dataDiv'
              data-index="4"
              onClick={() => {
                setFlag(4)
              }}
            >
              <img className='fourthImg' src={footer4} alt="icon" />
            </div>
            <div
              className='dataDiv'
              data-index="5"
              onClick={() => {
                setFlag(5)
              }}
            >
              <a href="https://www.instagram.com/hairx.app/">
                <img className='fifthImg' src={footer5} alt="icon" />
              </a>
            </div>
          </div>

          <div className='scrollbar'>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default Footer
