import hairstateImg1 from "../../src/assests/images/hairstate-1.jpg";
import hairstateImg2 from "../../src/assests/images/hairstate-2.jpg";
import hairstateImg3 from "../../src/assests/images/hairstate-3.jpg";
import hairstateImg4 from "../../src/assests/images/hairstate-4.jpg";
import hairstateImg5 from "../../src/assests/images/hairstate-5.jpg";
import hairLength1 from "../../src/assests/images/hairLength-1.jpg";
import hairLength2 from "../../src/assests/images/hairLength-2.jpg";
import hairLength3 from "../../src/assests/images/hairLength-3.jpg";
import hairLength4 from "../../src/assests/images/hairLength-4.jpg";

const HairStateData = [
    {
        id: 1,
        image: hairstateImg5,
        title: 'RECENT BIG CHOP',
        alt: "RECENT BIG CHOP"
    },
    {
        id: 2,
        image: hairstateImg4,
        title: 'Natural (Loose)',
        alt: "Natural (Loose)"
    },
    {
        id: 3,
        image: hairstateImg1,
        title: 'Natural (LoC’d)',
        alt: "Natural (LoC’d)"
    },
    {
        id: 4,
        image: hairstateImg3,
        title: 'TRANSITIONING',
        alt: "TRANSITIONING"
    },
    {
        id: 5,
        image: hairstateImg2,
        title: 'RELAXED',
        alt: "RELAXED"
    },

]

const HairConcernData = [
    { name: "Dryness or Manageability", id: "1" },
    { name: "Itchy or Flaky Scalp", id: "2" },
    { name: "Breakage", id: "3" },
    { name: "Edges or Hairline", id: "4" },
    { name: "Hair Growth or Hair Loss", id: "5" },
    { name: "Generally Overwhelmed", id: "6" },
    { name: "Heat Damage", id: "7" },
    { name: "None", id: "8" }
]

const HairLengthData = [
    {
        id: 1,
        image: hairLength1,
        title: 'MINI AFRO',
        alt: "MINI AFRO"
    },
    {
        id: 2,
        image: hairLength2,
        title: 'neck & shoulder',
        alt: "neck & shoulder"
    },
    {
        id: 3,
        image: hairLength3,
        title: 'shoulder & armpit',
        alt: "shoulder & armpit"
    },
    {
        id: 4,
        image: hairLength4,
        title: 'armpit & mid-back',
        alt: "armpit & mid-back"
    },

]

const LifeStyle1Data1 = [
    { id: 1, name: 'yes' },
    { id: 2, name: 'no' },
]

const LifeStyle1Data2 = [
    { id: 1, name: 'Chlorine </br> (At least Once)' },
    { id: 2, name: 'Air conditioning' },
    { id: 3, name: 'Central Heating' },
]

const LifeStyle1Data3 = [
    { id: 1, name: 'Less than once </br> a week' },
    { id: 2, name: '1-3 times </br> a week' },
    { id: 3, name: '4 or more time </br> a week' },
]
export { HairStateData, HairConcernData, HairLengthData, LifeStyle1Data1, LifeStyle1Data2, LifeStyle1Data3 };