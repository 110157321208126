import './App.scss';
import { Outlet, useLocation } from 'react-router';
import Header from './components/Header/Header';
import { useEffect } from 'react';
import FooterPage from "./pages/Footer/FooterPage";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, [pathname]);

    return null;
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Outlet />
      <FooterPage/>
    </div>
  );
}

export default App;
