import React, { Fragment, useEffect, useState } from 'react';
import "./results.scss";
import preparingImg from "../../assests/images/result-preparing.jpg";
import Desktop from '../Desktop/Desktop';
import GetResult from './GetResult';

const Preparing = () => {
  const [showPreparing, setShowPreparing] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreparing(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      <Desktop />
      {
        !showPreparing ?
          <div className="preparing">
            <img src={preparingImg} alt="preparing" />
            <div className='preparing-info'>
              <h1>
                all done!
              </h1>
              <p>We’re just preparing your results...</p>
            </div>
            <div className="loader"></div>
          </div> :
          <GetResult />
      }
    </Fragment>
  )
}

export default Preparing
