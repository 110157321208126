import React, { Fragment, useEffect, useState } from 'react';
import "./quiz.scss";
import Desktop from '../Desktop/Desktop';
import { Outlet, useLocation, useNavigate } from 'react-router';

const Quiz = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('/quiz/name');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const pageMap = {
    '/quiz/hairState': 'hairState',
    '/quiz/hairConcern': 'hairConcerns',
    '/quiz/hairLength': 'hairLength',
    '/quiz/lifeStyle1': 'lifeStyle1',
    '/quiz/lifeStyle2': 'lifeStyle2',
    '/quiz/lifeStyle3': 'lifeStyle3',
    '/quiz/select-product': 'dropdown'
  };

  const progressWidths = {
    hairState: '14.5%',
    hairConcerns: '28.5%',
    hairLength: '42.5%',
    lifeStyle1: '56.5%',
    lifeStyle2: '70.5%',
    lifeStyle3: '85.5%',
    dropdown: '100%'
  };

  const currentWidth = progressWidths[currentPage] || '0%';
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }, [pathname]);
    return null;
  };

  useEffect(() => {
    if (location.pathname !== "/quiz") {
      const newPage = pageMap[location.pathname] || '/quiz';
      setCurrentPage(newPage);
      const reset = (e) => {
        e.preventDefault();
        setShowModal(true);
      }
      window.addEventListener('load', reset);
      return () => {
        window.removeEventListener('load', reset);
      }
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Desktop />
      <ScrollToTop />
      <div className='quiz'>
        {
          (location.pathname !== '/quiz' && location.pathname !== '/quiz/name') &&
          <div className='progressMainDiv'>
            <div className='progress'>
              <div className="progress-bar" style={{ width: currentWidth }}>
                <div
                  role="progressbar"
                  aria-valuenow={parseInt(currentWidth)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        }
        <Outlet />
      </div>
      {
        location.pathname !== '/quiz' &&
        <div className='modalView'>
          <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden={!showModal}
            style={{ display: showModal ? 'block' : 'none' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  Are you sure? It'll redirect you to the first page.
                </div>
                <div className="footer">
                  <button
                    type="button"
                    className="btn btn-bg btn-cancel btnRadius"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-bg btnRadius"
                    onClick={() => {
                      navigate('/quiz');
                      setShowModal(false);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

export default Quiz;
