import React, { Fragment } from 'react';
import Preparing from '../../components/Results/Preparing';


const ResultPage = () => {
  return (
    <Fragment>
      <Preparing />
    </Fragment>
  )
}

export default ResultPage
