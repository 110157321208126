import { useDispatch, useSelector } from 'react-redux';
import { LifeStyle1Data3 } from "../../../src/utils/quizStaticData";
import { Fragment } from 'react';
import { setLifeStyle3 } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';


const LifeStyle3 = () => {
  const dispatch = useDispatch();
  const { lifeStyle3 } = useSelector(state => state?.quiz);
  const navigate = useNavigate();
  return (
    <Fragment >
      <div className='lifeStyle3 centerCommon'>
        <div className='lifeStyle-titles widthCommon centerCommon'>
          <h2>
            Lifestyle
          </h2>
          <p>
            How often do you work out (not including swimming)?
          </p>
        </div>
        <div className='lifeStyle3-type'>
          {
            LifeStyle1Data3?.map((data) => {
              return (
                <button
                  style={{ backgroundColor: data?.id === lifeStyle3 ? '#EF4D9233' : 'transparent' }}
                  key={data?.id}
                  className=' text'
                  onClick={() => {
                    dispatch(setLifeStyle3(data?.id))
                  }}
                  dangerouslySetInnerHTML={{__html:data?.name}}
                >
                </button>
              )
            })
          }
        </div>
        <div className='btndiv lifestyle3Btn'>
          <button
            className='btn btncommon btn-back'
            onClick={() => {
              navigate("/quiz/lifeStyle2");
            }}
          >
            BACK
          </button>
          <button
            className='btn-bg btncommon '
            onClick={() => {
              navigate("/quiz/select-product");
            }}
          >
            NEXT
          </button>
        </div>


      </div>
    </Fragment>
  )
}

export default LifeStyle3
