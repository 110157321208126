import { useDispatch, useSelector } from 'react-redux';
import { LifeStyle1Data2 } from "../../../src/utils/quizStaticData";
import { Fragment } from 'react';
import { setLifeStyle2 } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';

const LifeStyle2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { lifeStyle2 } = useSelector(state => state?.quiz);
    return (
        <Fragment >
            <div className='lifeStyle2 centerCommon'>
                <div className='lifeStyle-titles widthCommon centerCommon'>
                    <h2>
                        Lifestyle
                    </h2>
                    <p>
                        Do you expect to have significant exposure to any of these factors over the next few weeks?
                    </p>
                </div>
                <div className='lifeStyle2-type'>
                    {
                        LifeStyle1Data2?.map((data) => {
                            return (
                                <button
                                    style={{ backgroundColor: data?.id === lifeStyle2 ? '#EF4D9233' : 'transparent' }}
                                    key={data?.id}
                                    className=' text'
                                    onClick={() => {
                                        dispatch(setLifeStyle2(data?.id))
                                    }}
                                    dangerouslySetInnerHTML={{__html:data?.name}}
                                >
                                </button>
                            )
                        })
                    }
                </div>
                <div className='btndiv lifestyle2Btn'>
                    <button
                        className='btn btncommon btn-back'
                        onClick={() => {
                            navigate('/quiz/lifeStyle1');
                        }}
                    >
                        BACK
                    </button>
                    <button
                        className='btn-bg btncommon'
                        onClick={() => {
                            navigate('/quiz/lifeStyle3');
                        }}
                    >
                        NEXT
                    </button>
                </div>


            </div>
        </Fragment>
    )
}

export default LifeStyle2
