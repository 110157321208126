import React, { Fragment, useState } from 'react';
import wave from "../../assests/images/wave.svg";
import cleanseImg from "../../assests/images/res-cleanse.png";
import bag from "../../assests/images/add-to-bag.png";
import svg from "../../assests/images/Group 37811.svg";
import { getResultData } from '../../utils/resultData';
import renderStarImages from '../../hooks/RenderStar';
import saveProductImg from "../../assests/images/preparing-save-product.jpg";
import { useNavigate } from 'react-router';
import backBtn from "../../assests/images/backbutton (1).png"
const GetResult = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <div className="getResult">
        <div className='getResult-backBtn'
          onClick={() => {
            setShowModal(true);
          }}
        >
          <img src={backBtn} alt="back" />
          <p>
            back to results
          </p>
        </div>
        <div className='info'>
          <h2>
            Danielle, this is what we recommend for your hair
          </h2>
          <p>
            Based on your answers, here are the best selection of products and a 5-step routine for you to try.
          </p>
          <p>
            Add them to your basket to buy now or  set up an account to save them for later!
          </p>
        </div>
        {
          getResultData?.map((data) => {
            return (
              <div key={data?.id} >
                <div className='wave'>
                  <div className='line'>
                    <img src={wave} alt="" />
                  </div>
                  <div className='circle'>
                    {data?.id}
                  </div>
                </div>
                <div className='cleanse'>
                  <h2>
                    {data?.productName}
                  </h2>
                  <div className='cleanse-info'>
                    <div className='left'>
                      <img src={cleanseImg} alt="" />
                      <div className='starRating'>
                        {renderStarImages(5)}
                        <p>({data?.starNumber})</p>
                      </div>
                      <div className='rev'>
                        {data?.revTitle}
                      </div>
                    </div>
                    <div className='right'>
                      <div className='titles'>
                        <h4>
                          {data?.title}
                        </h4>
                        <p>
                          {data?.subTitle}
                        </p>
                      </div>
                      <div className='use'>
                        <h4>
                          {data?.useTitle}
                        </h4>
                        <p>
                          {data?.useDesc}
                        </p>
                      </div>
                      <div className='ingredients'>
                        <h4>
                          {data?.ingredientTitle}
                        </h4>
                        <p>
                          {data?.ingredientDesc}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='addToBag'>
                    <p className='price'>
                      {data?.price}
                    </p>
                    <div className='bag'>
                      <label key={1} className="custom-radio">
                        <input
                          type="checkbox"
                          name="option"
                          value="cleanse"
                          className="radio-input"
                        />
                        <span className="radio-checkmark">
                          <img
                            className="checkmark"
                            src={svg}
                            alt=""
                          />
                        </span>
                      </label>
                      <p>
                        {data?.bag}
                      </p>
                      <img src={bag} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        <div className='wave'>
          <div className='line'>
            <img src={wave} alt="" />
          </div>
        </div>
        <div className='guest'>
          <div className='guestCheckout'>
            <h6>
              5 products added to basket
            </h6>
            <h5>
              Total: <span>  £42.50</span>
            </h5>
            <button className='guest-btn btn-bg  '>
              continue to guest checkout
            </button>
            <p>
              or
            </p>
          </div>
          <div className='saveProduct'>
            <h4>
              Prefer to save these products for later?
            </h4>
            <p>
              Sign up to manage your <span>routine,</span> meet your <span>community</span> & explore more <span>benefits!</span>
            </p>
            <img src={saveProductImg} alt="product" />
            <button
              className='guest-btn btn-bg'
              onClick={() => {
                navigate('/sign-up')
              }}
            >
              sign up to save products
            </button>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${showModal ? 'show' : ''}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              Take a test again?
            </div>
            <div className="footer">
              <button
                type="button"
                className="btn btn-bg btn-cancel btnRadius"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-bg btnRadius"
                onClick={() => {
                  navigate('/quiz');
                  setShowModal(false);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default GetResult;
