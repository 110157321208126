import React, { Fragment } from 'react';
import "./desktop.scss";
import desktopImg from "../../assests/images/desktop.png";
import landscapeImg from "../../assests/images/landscape.png";

const Desktop = () => {
  return (
    <Fragment>
      <div className='mainView'>
        <div className='desktopView'>
          <img className='portraitMode' src={desktopImg} alt="desktop-img" />
          <img className='deskViewOnly' src={desktopImg} alt="desktop-img" />
        </div>
        <img className='landScapeView' src={landscapeImg} alt='landscapeImg' />
      </div>
    </Fragment>
  )
}

export default Desktop
