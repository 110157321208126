import React, { Fragment } from 'react'
import { HairConcernData } from "../../../src/utils/quizStaticData";
import { useDispatch, useSelector } from 'react-redux';
import { setHairConcern } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';

const HairConcerns = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { hairConcern } = useSelector(state => state?.quiz);

    return (
        <Fragment >
            <div className='hairConcerns centerCommon'>
                <div className='widthCommon centerCommon'>
                    <h2>
                        HAIR CONCERNS
                    </h2>
                    <p>
                        Do you have any particular hair concerns? (Select up to 3)
                    </p>
                </div>
                <div className='hairConcerns-type row' >
                    {
                        HairConcernData?.map((data) => {
                            return (
                                <div className='col-6' key={data?.id} >
                                    <button
                                        style={{ backgroundColor: data?.id === hairConcern ? '#EF4D9233' : 'transparent' }}
                                        key={data?.id}
                                        className='text'
                                        onClick={(e) => {
                                            dispatch(setHairConcern(data?.id))
                                        }}
                                    >
                                        {data.name}
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='btndiv'>
                    <button
                        className='btn btncommon btn-back'
                        onClick={() => {
                            navigate('/quiz/hairState');
                        }}
                    >
                        BACK
                    </button>
                    <button
                        className='btn-bg btncommon'
                        onClick={() => {
                            navigate('/quiz/hairLength');
                        }}
                    >
                        NEXT
                    </button>
                </div>


            </div>
        </Fragment>
    )
}

export default HairConcerns
