import React from 'react'
import  "../../src/components/Desktop/desktop.scss";
const NotFound = () => {
    return (
        <div className='notFound'>
            This Page is not found.
        </div>
    )
}

export default NotFound
