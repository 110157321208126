import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import { setProduct } from '../../Slice/quizSlice';

const SelectProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { product } = useSelector(state => state?.quiz)
  const handleChange = (e) => {
    dispatch(setProduct(e.target.getAttribute("data-value")));
  }
  const dropDownData = [
    {
      val: " Whipped Batter"
    },
    {
      val: "Janet Collection Noir Afro Marley Braid"
    },
    {
      val: " Manshanu-Clarified Butter (Ghee)-Food grade"
    },
    {
      val: "  Pomegranate & Honey Twisting Soufflé"
    },
    {
      val: "  Rosemary Mint Scalp & Hair Strengthening Oil"
    },
    {
      val: " Pomegranate & Honey Coil Sculpting Custard"
    },
    {
      val: " Algae Renew Deep Conditioner"
    },
    {
      val: "  Honey And Sage Deep Conditioner"
    },
  ]
  return (
    <Fragment>
      <div className='productSelect'>
        <div className='dropdown'>
          <label htmlFor="exampleDataList" className="form-label">Please select any hairRx products that you are using</label>
          <div className='drp'>
            <button className="btn  dropdown-toggle drp-Btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              {
                product !== "" ?
                  product :
                  "Select Product"
              }
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1"
            >
              {
                dropDownData?.map((data, index) => (
                  <li key={index}>
                    <a
                      className="dropdown-item"
                      href={undefined}
                      data-value={data?.val}
                      onClick={(e) => {
                        handleChange(e)
                      }}
                    >
                      {data?.val}
                    </a></li>
                ))
              }

            </ul>
          </div>
        </div>
        <div className='btndiv'>
          <button
            className='btn btncommon btn-back'
            onClick={() => {
              navigate("/quiz/lifeStyle3");
            }}
          >
            BACK
          </button>
          <button
            className='btn-bg btncommon '
            onClick={() => {
              navigate("/result");
            }}
          >
            NEXT
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default SelectProduct
