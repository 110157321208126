import React, { Fragment } from 'react'
import quizFirst from "../../assests/images/quiz-first.jpg";
import "./quiz.scss";
import { useNavigate } from 'react-router';

const QuizIntro = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className='quizIntro'>
        <img src={quizFirst} alt='quiz' />
        <div className='quizIntro__info'>
          <h2>
            LET’S GET TO <br /> KNOW YOU!
          </h2>
          <p>
            Answer a few questions about your hair, lifestyle and budget so we can recommend the perfect product and routine for you
          </p>
          <div className='quizIntro__info-btn'>
            <button
              className='btn-bg btncommon'
              onClick={() => {
                navigate('/quiz/name');
              }} >
              START
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default QuizIntro
