import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LifeStyle1Data1 } from "../../../src/utils/quizStaticData";
import { setLifeStyle1 } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';

const LifeStyle1 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { lifeStyle1 } = useSelector(state => state?.quiz);
    return (
        <Fragment >
            <div className='lifeStyle centerCommon'>
                <div className='lifeStyle-titles widthCommon centerCommon'>
                    <h2>
                        Lifestyle
                    </h2>
                    <p>
                        Will you be flying in the next few weeks?
                    </p>
                </div>
                <div className='lifeStyle-type'>
                    {
                        LifeStyle1Data1?.map((data) => {
                            return (
                                <button
                                    style={{ backgroundColor: data?.id === lifeStyle1 ? '#EF4D9233' : 'transparent' }}
                                    key={data?.id}
                                    className=' text'
                                    onClick={() => {
                                        dispatch(setLifeStyle1(data?.id))
                                    }}
                                >
                                    {data?.name}
                                </button>
                            )
                        })
                    }
                </div>
                <div className='btndiv'>
                    <button
                        className='btn btncommon btn-back'
                        onClick={() => {
                            navigate('/quiz/hairLength');
                        }}
                    >
                        BACK
                    </button>
                    <button
                        className='btn-bg btncommon'
                        onClick={() => {
                            navigate('/quiz/lifeStyle2');
                        }}
                    >
                        NEXT
                    </button>
                </div>


            </div>
        </Fragment>
    )
}

export default LifeStyle1
