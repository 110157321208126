import React, { Fragment } from 'react';
import { HairStateData } from "../../../src/utils/quizStaticData";
import svg from "../../../src/assests/images/Group 37811.svg";
import { useDispatch, useSelector } from 'react-redux';
import { setHairState } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const HairState = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hairState } = useSelector(state => state?.quiz);
  return (
    <Fragment >
      <div className='hairState centerCommon'>
        <div className='widthCommon centerCommon'>
          <h2>
            HAIR STATE
          </h2>
          <p>
            What is the current state of your hair? <br />(tick one box)
          </p>
        </div>
        <div className='hairState-type row'>
          {
            HairStateData?.map((data, index) => {
              return (
                <div key={index} className='col-6' 
                onClick={()=>{
                  dispatch(setHairState(data?.id));
                }}
                >
                  <LazyLoadImage
                    src={data?.image}
                    alt={data?.alt}
                  />
                  <h5>{data?.title}</h5>
                  <label key={data?.id} className="custom-radio">
                    <input
                      type="radio"
                      name="option"
                      value={hairState === data?.id}
                      checked={hairState === data?.id}
                      className="radio-input"
                      onChange={()=>{}}
                    />
                    <span className="radio-checkmark">
                      <img
                        className="checkmark"
                        src={svg}
                        alt=""
                      />
                    </span>
                  </label>
                </div>
              )
            })
          }
        </div>
        <div className='btndiv'>
          <button
            className='btn btncommon btn-back'
            onClick={() => {
              navigate('/quiz/name');
            }}
          >
            BACK
          </button>
          <button
            className='btn-bg btncommon '
            onClick={() => {
              navigate('/quiz/hairConcern');
            }}
          >
            NEXT
          </button>
        </div>
      </div>
    </Fragment>
  )
}

export default HairState
