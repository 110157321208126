const getResultData = [
    {
        id: "1",
        productName: "CLEANSE",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    },
    {
        id: "2",
        productName: "CONDITION",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    },
    {
        id: "3",
        productName: "MOISTURE",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    },
    {
        id: "4",
        productName: "SEAL",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    },
    {
        id: "5",
        productName: "STYLE",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    },
]
const alternativesData=[
    {
        id: "1",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    }, 
    {
        id: "2",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    }, 
    {
        id: "3",
        starNumber: "91",
        revTitle: "view reviews",
        title: "MONGONGO OIL EXFOLIATING SHAMPOO",
        subTitle: "Mielle Organics",
        useTitle: "HOW TO USE",
        useDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        ingredientTitle: "Ingredients",
        ingredientDesc: "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        price: "£8.50",
        bag: "Add to bag",
        altOptionBtn: "view alternative options"
    }, 
]
export { getResultData,alternativesData };