import React, { Fragment } from 'react'
import quizStart from "../../assests/images/quiz-start.jpg";
import "./quiz.scss";
import { useDispatch, useSelector } from 'react-redux';
import { setName } from '../../Slice/quizSlice';
import { useNavigate } from 'react-router';

const QuizStart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { name } = useSelector(state => state.quiz);
    return (
        <Fragment>
            <div className='quizStart centerCommon'>
                <img
                    src={quizStart}
                    alt='quiz' />
                <div className='quizStart__info '>
                    <div className='widthCommon centerCommon'>
                        <h2 >
                            Let’s Get Started!
                        </h2>
                        <p>
                            First of all, what's your name?
                        </p>
                    </div>
                    <input
                        className='name'
                        placeholder='Name'
                        type='text'
                        name='name'
                        id='name'
                        value={name}
                        onChange={(e) => {
                            dispatch(setName(e.target.value));
                        }}
                    />
                    <div className='btndiv nameBtnDiv'>
                        <button
                            className='btn btncommon btn-back'
                            onClick={() => {
                                navigate('/quiz')
                            }}
                        >
                            BACK
                        </button>
                        <button
                            className='btn-bg btncommon'
                            onClick={() => {
                                navigate('/quiz/hairState')
                            }}
                        >
                            NEXT
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default QuizStart
