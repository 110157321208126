import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import HomePage from "../pages/HomePage/HomePage";
import Quiz from "../components/Quiz/Quiz";
import ResultPage from "../pages/Results/ResultPage";
import GetResult from "../components/Results/GetResult";
import NotFound from "../components/NotFound";
import QuizStart from "../components/Quiz/QuizStart";
import HairState from "../components/Quiz/HairState";
import HairConcerns from "../components/Quiz/HairConcerns";
import HairLength from "../components/Quiz/HairLength";
import LifeStyle1 from "../components/Quiz/LifeStyle1";
import LifeStyle2 from "../components/Quiz/LifeStyle2";
import LifeStyle3 from "../components/Quiz/LifeStyle3";
import QuizIntro from "../components/Quiz/QuizIntro";
import SelectProduct from "../components/Quiz/Dropdown";

export const Routes = createBrowserRouter(
    [{
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                element: <HomePage />
            },
            {
                path: "quiz",
                element: <Quiz />,
                children: [
                    {
                        path: "",
                        element: <QuizIntro />
                    },
                    {
                        path: "name",
                        element: <QuizStart />
                    },
                    {
                        path: "hairState",
                        element: <HairState />
                    },
                    {
                        path: "hairConcern",
                        element: <HairConcerns />
                    },
                    {
                        path: "hairLength",
                        element: <HairLength />
                    },
                    {
                        path: "lifeStyle1",
                        element: <LifeStyle1 />
                    },
                    {
                        path: "lifeStyle2",
                        element: <LifeStyle2 />
                    },
                    {
                        path: "lifeStyle3",
                        element: <LifeStyle3 />
                    },
                    {
                        path: "select-product",
                        element: <SelectProduct />
                    },
                ]
            },
            {
                path: "result",
                children: [
                    {
                        path: "",
                        element: <ResultPage />
                    },
                    {
                        path: "preparing",
                        element: <GetResult />
                    },
                ]
            },
            {
                path: '*',
                element: <NotFound />
            }
        ],

    },


    ]
)