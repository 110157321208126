import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    name: '',
    hairState: "",
    hairConcern: "",
    hairLength: "",
    lifeStyle1: "",
    lifeStyle2: "",
    lifeStyle3: "",
    product: "",
    quizData: []
}

const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        setHairState: (state, action) => {
            state.hairState = action.payload;
        },
        setHairConcern: (state, action) => {
            state.hairConcern = action.payload;
        },
        setHairLength: (state, action) => {
            state.hairLength = action.payload;
        },
        setLifeStyle1: (state, action) => {
            state.lifeStyle1 = action.payload;
        },
        setLifeStyle2: (state, action) => {
            state.lifeStyle2 = action.payload;
        },
        setLifeStyle3: (state, action) => {
            state.lifeStyle3 = action.payload;
        },
        setProduct: (state, action) => {
            state.product = action.payload;
        },
        setQuizData: (state, action) => {
            state.quizData = action.payload;
        },
    }
});

export const {
    setName,
    setHairConcern,
    setHairState,
    setHairLength,
    setLifeStyle1,
    setLifeStyle2,
    setLifeStyle3,
    setProduct,
    setQuizData
} = quizSlice.actions;
export default quizSlice.reducer;