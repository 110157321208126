import React, { Fragment } from 'react'
import "./homePage.scss";
import image from "../../assests/images/hair-rx-logo.png"
import { useNavigate } from 'react-router';
import Desktop from '../Desktop/Desktop';
import useOrientation from '../../hooks/LandscapeHook';
import { setHairConcern, setHairLength, setHairState, setLifeStyle1, setLifeStyle2, setLifeStyle3, setName, setProduct } from '../../Slice/quizSlice';
import { useDispatch } from 'react-redux';

const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  return (
    <Fragment>
      <Desktop />
        <div className='homePage container425'>
          <h1>
            THE HAIRCARE EXPERIENCE YOU DESERVE
          </h1>
          <img className='homePage-img' src={image} alt='home' />
          <p>
            Take the headache out of your haircare, with expert personalised product recommendations and fall in love with your hair again!
          </p>
          <button className='btn-bg'
            onClick={() => {
              navigate('/quiz');
            }}
          >
            TAKE THE QUIZ!
          </button>
          <button 
          className='hideBtn'
            onClick={() => {
              dispatch(setName(""));
              dispatch(setHairState(""));
              dispatch(setHairConcern(""));
              dispatch(setHairLength(""));
              dispatch(setLifeStyle1(""));
              dispatch(setLifeStyle2(""));
              dispatch(setLifeStyle3(""));
              dispatch(setProduct(""));
            }}
          >
            reset
          </button>
        </div>
    </Fragment>
  )
}

export default Home
