import React, { Fragment } from 'react';
import Footer from '../../components/Footer/Footer';

const FooterPage = () => {
  return (
    <Fragment>
        <Footer/>
    </Fragment>
  )
}

export default FooterPage
